body {
  margin: 0;
  font-family: 'Gotham', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5 {
  margin: 0;
}


* {
  /* ensure padding is factored into the width of every element */
  box-sizing: border-box;
}

@font-face {
	font-family: 'Gotham';
	src:local('Gotham'), url('./fonts/woff2/Gotham-Light_Web.woff2') format('woff2'),
		url('./fonts/woff/Gotham-Light_Web.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham';
	src:local('Gotham'), url('./fonts/woff2/Gotham-LightItalic_Web.woff2') format('woff2'),
		url('./fonts/woff/Gotham-LightItalic_Web.woff') format('woff');
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham';
	src:local('Gotham'), url('./fonts/woff2/Gotham-Book_Web.woff2') format('woff2'),
		url('./fonts/woff/Gotham-Book_Web.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham';
	src:local('Gotham'), url('./fonts/woff2/Gotham-BookItalic_Web.woff2') format('woff2'),
		url('./fonts/woff/Gotham-BookItalic_Web.woff') format('woff');
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham';
	src:local('Gotham'), url('./fonts/woff2/Gotham-Medium_Web.woff2') format('woff2'),
		url('./fonts/woff/Gotham-Medium_Web.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham';
	src:local('Gotham'), url('./fonts/woff2/Gotham-MediumItalic_Web.woff2') format('woff2'),
		url('./fonts/woff/Gotham-MediumItalic_Web.woff') format('woff');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham';
	src:local('Gotham'), url('./fonts/woff2/Gotham-Bold_Web.woff2') format('woff2'),
		url('./fonts/woff/Gotham-Bold_Web.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Gotham';
	src:local('Gotham'), url('./fonts/woff2/Gotham-BoldItalic_Web.woff2') format('woff2'),
		url('./fonts/woff/Gotham-BoldItalic_Web.woff') format('woff');
	font-weight: 700;
	font-style: italic;
	font-display: swap;
}